
import { defineComponent, ref } from 'vue'
import CampaignsSentFilter from '@/components/pages/campaigns/details/sent/CampaignsSentFilter.vue'
import CampaignsSentTable from '@/components/pages/campaigns/details/sent/CampaignsSentTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import { useFilters } from '@/compositions/filters'
import { campaignsSentMessages } from '@/definitions/shared/filters/filtersList/data'
import { useModes } from '@/compositions/modes'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'

export default defineComponent({
  components: {
    TmEmptyState,
    CampaignsSentTable,
    CampaignsSentFilter,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const expand = ref(false)
    const search = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'name' },
      { text: 'Phone', value: 'phone' },
      { text: 'Message', value: 'message', expandPossible: true },
      { text: 'Status', value: 'status' },
    ])
    const tableItems = ref(getTableData('campaignsSentMessages'))
    const { filters, isDefault, reset } = useFilters(campaignsSentMessages)

    return {
      tableItems,
      filters,
      isEmptyMode,
      isDefault,
      reset,
      search,
      expand,
      tableHeaders,
    }
  },
})
